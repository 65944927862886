import React from 'react'
import PageContainer from '@/components/PageContainer'
import { Box, VStack, Center, Heading, Text } from 'ui-components'
import { NextPage } from 'next'

import CoverImage from 'public/images/bg-cover-404.jpg'

interface Props {
  children?: React.ReactNode
  title: string
  errorCode: number
  text?: string
}

const ErrorPageLayout: NextPage<Props> = ({ title, children, text, errorCode }) => {
  return (
    <PageContainer title={'Error ' + errorCode} collapse showNav={false}>
      <Center
        bgImage={`url('${CoverImage}')`}
        bgPosition="center"
        bgRepeat="no-repeat"
        bgSize="cover"
        width="100%"
        h="64"
      >
        <VStack p={6} spacing={1} color="white" textAlign="center">
          <Heading fontSize="40px" lineHeight="1" letterSpacing="-3px">
            {title}
          </Heading>
        </VStack>
      </Center>
      <Box px="6" py="8">
        <Text opacity="0.7" fontSize="md" textAlign="center">
          {text}
        </Text>
        {children}
      </Box>
    </PageContainer>
  )
}

export default ErrorPageLayout
