import React from 'react'
import { NextPage } from 'next'
import ErrorPageLayout from '@/components/ErrorPage'

const Error404: NextPage = () => {
  return (
    <ErrorPageLayout
      errorCode={404}
      title="PAGE NOT FOUND"
      text="Sorry, we can’t seem to find the page you are looking for."
    />
  )
}
export default Error404
